<template>
  <div id="ListUsers" class="container max-w-6xl mx-auto pt-20 pb-20 px6">

    <div class="flex flex-wrap justify-between mt-4 mb-6 mx-4 md:mx-0">
        <div class="basis-1/4">
            <button class="
            block
            w-full
            bg-innoorange
            hover:bg-innoorange-hover
            text-white
            rounded-sm
            py-3
            text-sm
            tracking-wide  
            "
            type="submit"
            @click="goAdminHome()">
            Back
            </button>
        </div>
        <div class="w-1/4 ml-auto">
          <button class="
            block
            w-full
            bg-innoorange
            hover:bg-innoorange-hover
            text-white
            rounded-sm
            py-3
            text-sm
            tracking-wide  
          "
            type="submit"
            @click="goToAddUser()">
            Add User
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">Users</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    
    <div class="pt-5 pb-5">
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
          </div>
          <input v-model="query" @keyup.enter="searchUsers" type="text" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-innogreen focus:border-innogreen dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-innogreen dark:focus:border-innogreen" placeholder="Search ChefPaw Users..." required>
          <button v-if="!searching" @click="searchUsers" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">Search</button>
          <button v-if="searching" type="button" class="text-white absolute right-2.5 bottom-2.5 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-innoorange dark:focus:ring-innoorange-hover">
            <div class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
              <svg class="w-5 h-5 animate-spin ml-auto mr-auto" fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"></path>
              </svg>
            </div>
          </button>
      </div>
    </div>

    <div v-if="!searching && users.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div class="flex justify-center items-center mt-4 space-x-6">
          <!-- Previous Button -->
          <button 
            @click="prevPage" :disabled="currentPage === 1 || totalPages === 0"
            :class="{'bg-gray-300 text-gray-700': currentPage !== 1 && totalPages !== 0, 'bg-gray-200 text-gray-400 cursor-not-allowed': currentPage === 1 || totalPages === 0}"
            class="px-4 py-2 rounded">
            Previous
          </button>

          <!-- Page Select Dropdown -->
          <select v-model="currentPage" @change="searchUsers()" class="px-4 py-2 bg-gray-300 text-gray-700 rounded">
            <option v-for="page in totalPages" :key="page" :value="page">
              Page {{ page }}
            </option>
          </select>

          <!-- Next Button -->
          <button
            @click="nextPage"
            :disabled="currentPage === totalPages || totalPages === 0"
            :class="{'bg-gray-300 text-gray-700': currentPage !== totalPages && totalPages !== 0, 'bg-gray-200 text-gray-400 cursor-not-allowed': currentPage === totalPages || totalPages === 0}"
            class="px-4 py-2 rounded"
            >
            Next
          </button>
        </div>

        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-4">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <!-- First Name Column -->
              <th scope="col" class="px-6 py-3 cursor-pointer" @click="sortColumn('first_name')">
                First Name 
                <span v-if="sortBy === 'first_name'">
                  <i v-if="sortOrder === 'asc'" class="fa fa-arrow-up"></i>
                  <i v-else class="fa fa-arrow-down"></i>
                </span>
              </th>

              <!-- Last Name Column -->
              <th scope="col" class="px-6 py-3 cursor-pointer" @click="sortColumn('last_name')">
                Last Name
                <span v-if="sortBy === 'last_name'">
                  <i v-if="sortOrder === 'asc'" class="fa fa-arrow-up"></i>
                  <i v-else class="fa fa-arrow-down"></i>
                </span>
              </th>

              <!-- Email Column -->
              <th scope="col" class="px-6 py-3 cursor-pointer" @click="sortColumn('email')">
                Email
                <span v-if="sortBy === 'email'">
                  <i v-if="sortOrder === 'asc'" class="fa fa-arrow-up"></i>
                  <i v-else class="fa fa-arrow-down"></i>
                </span>
              </th>

              <!-- Account Created At Column -->
              <th scope="col" class="px-6 py-3 cursor-pointer" @click="sortColumn('created_at')">
                Created
                <span v-if="sortBy === 'created_at'">
                  <i v-if="sortOrder === 'asc'" class="fa fa-arrow-up"></i>
                  <i v-else class="fa fa-arrow-down"></i>
                </span>
              </th>

              <!-- Last Login Column -->
              <th scope="col" class="px-6 py-3 cursor-pointer" @click="sortColumn('last_login')">
                Last Login
                <span v-if="sortBy === 'last_login'">
                  <i v-if="sortOrder === 'asc'" class="fa fa-arrow-up"></i>
                  <i v-else class="fa fa-arrow-down"></i>
                </span>
              </th>

              <th scope="col" class="px-6 py-3">Actions</th>
              <th scope="col" class="px-6 py-3">Status</th>
              <th scope="col" class="px-6 py-3">Role</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in users" :key="user.id"
                :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {{ user.first_name }}
              </th>
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {{ user.last_name }}
              </th>
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {{ user.email }}
              </th>
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {{  formatDate(user.created_at) }}
              </th>
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {{  userLastSuccessfulAuthAttemptValue(user) }}
              </th>
              <td class="px-6 py-4">
                <a href="javascript:void(0)" @click="becomeUser(user)" class="font-medium text-blue-600 dark:text-innogreen hover:underline">Become</a> |

                <a v-if="!isSuspended(user)" href="javascript:void(0)" @click="suspendUser(user)" class="font-medium text-blue-600 dark:text-innogreen hover:underline">Suspend</a>
                <a v-if="isSuspended(user)" href="javascript:void(0)" @click="unSuspendUser(user)" class="font-medium text-blue-600 dark:text-innogreen hover:underline">Un-Suspend</a>
                | <a href="javascript:void(0)" @click="goUserAuthAttempts(user)" class="font-medium text-blue-600 dark:text-innogreen hover:underline">View Logins</a>
                <div v-if="false">
                  <span v-if="!user.loading">
                    <a href="javascript:void(0)" @click="destroyIngredient(user)" class="font-medium text-blue-600 dark:text-innogreen hover:underline">Delete</a>
                  </span>
                  <span v-if="user.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                    <span class="visually-hidden">Destroying...</span>
                  </span>
                </div>
              </td>
              <td class="px-6 py-4">
                <i v-if="!isSuspended(user)" class="fa-solid fa-heart fa-xl text-innogreen"></i>
                <i v-if="isSuspended(user)" class="fa-solid fa-circle-minus fa-xl text-innoorange"></i>
              </td>
              <td class="px-6 py-4">
                <select class="
                        appearance-none
                        block
                        w-full
                        bg-white
                        text-gray-700
                        border
                        border-gray-400
                        rounded
                        py-3
                        px-4
                        leading-tight
                        focus:outline-none
                        focus:bg-white
                        focus:border-gray-500
                        "
                        v-model="userRoles[index]"
                        @change="changeUserRole(index)"
                >
                  <option v-for="role in userRoleOptions" :key="roleOptionKey(user, role)" :selected="role == userRoles[index]" :value="role">{{ role }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import IngredientService from '../../services/ingredient.service'
  import UserService from '../../services/user.service'
  import { useUserStore } from '../../store/user-store'
  import Swal from 'sweetalert2'
  import { useToast } from "vue-toastification"

  const router = useRouter()
  const userStore = useUserStore()
  const toast = useToast()

  let searching = ref(false)
  let query = ref('')
  let usersData = ref({})
  let users = ref([])
  let userRoles = ref([])
  let userRoleOptions = ref(['user', 'professional', 'admin'])
  let errors = ref([])

  let currentPage = ref(1)
  let totalPages = ref(0) // Adjust based on your backend data
  let sortBy = ref('created_at')
  let sortOrder = ref('desc') // or 'asc'

  onMounted(async () => {
      searchUsers()
  })

  const searchUsers = async () => {
    searching.value = true
    try {
      let res = await UserService.adminSearchUsers(query.value, currentPage.value, sortBy.value, sortOrder.value)
      usersData.value = res.data.users
      users.value = usersData.value.data
      totalPages.value = usersData.value.last_page
      currentPage.value = usersData.value.current_page
      resetUserRoles()
      searching.value = false
    } catch (err) {
      console.log(err)
      searching.value = false
    }
  }

  const resetUserRoles = () => {
    userRoles.value = []
    for (let i = 0; i < users.value.length; i++) {
      if (users.value[i].roles.length > 0) {
        userRoles.value.push(users.value[i].roles[0].name)
      } else {
        userRoles.value.push('user')
      }
    }
  }

  const changeUserRole = async (index) => {
    try {
      let res = await UserService.adminSetUserRole(users.value[index].id, userRoles.value[index])
      toast.success(res.data.message)
    } catch (err) {
      if (err.response.data.errors) errors.value = err.response.data.errors
      Swal.fire({
        title: 'Error',
        text: err.response.data.message,
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
    }
  }

  const becomeUser = async (user) => {
    try {
      let res = await UserService.adminBecomeUser(user.id)
      console.log('resData', res.data)

      console.log('admin user.token', userStore.token)
      userStore.adminSetBecomeUserDetails(userStore.token, res)
      router.push('/account/home')
    } catch (err) {
      if (err.response.data.errors) errors.value = err.response.data.errors
      Swal.fire({
        title: 'Error',
        text: err.response.data.message,
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
    }
  }

  const suspendUser = async (user) => {
    Swal.fire({
      title: 'Are you sure?',
      text: user.first_name + "will be fully logged out and be prevented from logging in.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F28705',
      cancelButtonColor: '#618C03',
      confirmButtonText: 'Yes, suspend!'
    }).then( async (result) => {
      if (result.isConfirmed) {
        try {
          await UserService.adminSuspendUser(user.id)
          searchUsers()
        } catch (err) {
          Swal.fire({
            title: 'Error',
            text: err.response.data.message,
            icon: 'error',
            confirmButtonColor: '#F28705'
          })
          searching.value = false
        }
      }
    })
  }

  const unSuspendUser = async (user) => {
    try {
      await UserService.adminUnsuspendUser(user.id)
      searchUsers()
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: err.response.data.message,
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
      searching.value = false
    }
  }

  const isSuspended = (user) => {
    return user.roles.some(role => role.name === 'suspended');
  }

  const destroyIngredient = async (ingredient) => {
    ingredient.loading = true
    Swal.fire({
      title: 'Are you sure?',
      text: ingredient.name + "will be permanently deleted.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F28705',
      cancelButtonColor: '#618C03',
      confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
      if (result.isConfirmed) {
        try {
          await IngredientService.adminDeleteIngredient(ingredient.id)
          searchUsers()
        } catch (err) {
          errors.value = err.response.data.errors
          searching.value = false
        }
      }
    })
  }

  const roleOptionKey = (user, role) => {
    return user.id + '-' + role
  }

  // const displayRole = (user) => {
  //   if (user.roles.length == 0) return 'User'
  //   else if (user.roles[0].name == 'admin') return 'Admin'
  //   else if (user.roles[0].name == 'professional') return 'Professional'
  // }

  const goToAddUser = () => {
    router.push({ name: 'AdminAddUser' })
  }

  const goUserAuthAttempts = (user) => {
    router.push({ name: 'ListUserAuthAttempts', params: { id: user.id} })
  }

  const prevPage = () => {
    if (currentPage.value > 1) {
      currentPage.value--;
      searchUsers();
    }
  }
  const nextPage = () => {
    if (currentPage.value < totalPages.value) {
      currentPage.value++;
      searchUsers();
    }
  }
  // const goToPage = (page) => {
  //   currentPage.value = page;
  //   fetchUsers();
  // }
  const sortColumn = (column) => {
    if (sortBy.value === column) {
      sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
    } else {
      sortBy.value = column;
      sortOrder.value = 'asc';
    }
    searchUsers();
  }

  const formatDate = (d) => {
    let dateStr = d
    if (!dateStr.endsWith(".000000Z")) dateStr = dateStr + " UTC";
    const dateObj = new Date(`${dateStr}`);

    // Array of month names to get the month as a string
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const day = dateObj.getUTCDate();          // get day of the month
    const month = monthNames[dateObj.getUTCMonth()];  // get month name
    const year = dateObj.getUTCFullYear();     // get full year
    
    return `${month} ${day}, ${year}`;  // Construct the desired format
  }

  const userLastSuccessfulAuthAttemptValue = (user) => {
    if (user.last_login) {
      return formatDate(user.last_login)
    } else {
      return 'Never'
    }
  }

  const goAdminHome = () => {
    router.replace({ name: 'AdminHome' })
  }
</script>
